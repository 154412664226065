<script setup lang="ts">
import { FetchError } from "ofetch";
import { z } from "zod";
import type { FormState } from "~/models/forms";

const { locale, t } = useI18n();
const { returnUrl } = useRoute().query;

// todo add returnUrl?
definePageMeta({
  layout: "anonymous",
  auth: {
    anonymousOnly: true,
    redirectAuthenticatedTo: "/",
  },
});

useHead({
  title: () => t("auth.action.sign-in"),
});

const formState = ref<FormState>("mounting");
const errorMessage = ref("");

const { data: cmsData } = await useFetch("/api/cms/account-page", {
  query: { locale: locale },
});

const state = reactive({
  email: undefined,
  password: undefined,
});

const formValidationEmail = t("form.validation.email");
const formValidationRequired = t("form.validation.required");

const schema = z.object({
  email: z.string({ required_error: formValidationRequired }).email(formValidationEmail),
  password: z.string({ required_error: formValidationRequired }).min(6),
});

async function onSubmit() {
  formState.value = "pending";
  errorMessage.value = "";

  try {
    await $fetch("/api/auth/signin", {
      body: {
        username: state.email,
        password: state.password,
      },
      method: "POST",
    });

    formState.value = "success";

    // external for full page reload
    return navigateTo(returnUrl?.toString() || "/", { replace: true, external: true });
  }
  catch (error: FetchError | any) {
    // console.error("Sign In Exception", {
    //   error: error, // string version
    //   errorData: error.data, // contains more information if it's a fetcherror
    // });

    formState.value = "error";
    if (error instanceof FetchError) {
      if (error.data?.statusCode === 400 || error.data?.statusCode === 401) {
        errorMessage.value = t("auth.message.error.invalid-credentials");
        return;
      }
      errorMessage.value = t("auth.message.error.sign-in", { ErrorCode: error.data.statusCode });
      return;
    }

    errorMessage.value = error;
  }
}

onMounted(() => {
  formState.value = "idle";
});
</script>

<template>
  <CenterCard :title="t('auth.action.sign-in')">
    <p v-if="formState === 'error' && errorMessage" class="text-base text-red-700">
      {{ errorMessage }}
    </p>

    <div v-if="formState === 'success'" class="flex h-28 place-content-center items-center">
      <Loading class="size-8 text-green-700" />
    </div>

    <UForm v-if="formState !== 'success'" :state="state" :schema="schema" @submit="onSubmit">
      <UFormGroup :label="t('auth.label.email')" name="email" required>
        <UInput v-model="state.email" type="email" autocomplete="username" />
      </UFormGroup>

      <UFormGroup :label="t('auth.label.password')" name="password" required :ui="{ wrapper: '!mb-md' }">
        <UInput v-model="state.password" type="password" autocomplete="current-password" />
      </UFormGroup>

      <div class="flex flex-col items-start gap-xl">
        <NuxtLinkLocale to="/auth/reset-request" class="block">
          {{ $t("auth.action.forgot-password") }}
        </NuxtLinkLocale>

        <div v-if="cmsData?.legalPageText && cmsData?.legalPageLink">
          <span>{{ cmsData.legalPageText }}</span>&nbsp;
          <NuxtLinkLocale :to="`/legal/${cmsData.legalPageLink.slug}`" target="_blank">
            {{ cmsData.legalPageLink.title }}
          </NuxtLinkLocale>
        </div>

        <UButton
          type="submit" :loading="formState === 'pending'" :disabled="formState === 'mounting'"
          class="w-full justify-center sm:w-auto sm:justify-start"
        >
          {{ $t("auth.label.sign-in") }}
        </UButton>

        <span class="block">
          <span class="">{{ $t("auth.label.no-account-yet") }}</span>
          <NuxtLinkLocale
            to="/auth/register"
            class="ms-xs break-words"
          >{{ $t("auth.action.create-account") }}
          </NuxtLinkLocale>
        </span>
      </div>
    </UForm>
  </CenterCard>
</template>

<style scoped>
a {
  @apply text-blue hover:text-blue-700 font-medium underline;
}
</style>
